import React from "react";

// import imageCompression from "browser-image-compression";
import axios from "axios";
// import Card from "react-bootstrap/Card";

export default class imageCompressor extends React.Component {
  constructor() {
    super();
    this.state = {
      compressedLink:
        "",
 
    };
  }

setDownloadlink(res){
  console.log(res)
      const downloadLink = res.request.responseURL;
      this.setState({
        compressedLink: downloadLink
      })
      this.setState({ clicked: true });;

}

  // changeValue = e => {
  //   this.setState({ [e.target.name]: e.target.value });
  // };

  click = e => {
    e.preventDefault();

    this.uploadFile(e);
    
    return 1;
  };


  handle = e => {
    const imageFile = e.target.files[0];
    this.setState({
      originalImage: imageFile,
      outputFileName: imageFile.name,

    });
  };


  uploadFile(e) {
    e.preventDefault();
    const file = this.state.originalImage;
    const formData = new FormData();
    formData.append("file", file);

    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Cache-Control': 'no-cache'
        }
    }
        const url = `${window.location.protocol}//postlabel.nl/upload`


     axios.post(url, formData,config)
      .then(res => this.setDownloadlink(res)

        )
      .catch(err => console.warn(err));
  }

  render() {
    return (
      <div className="m-5">
        <div className="text-light text-center">
          <h1>postlabel.nl</h1>
          <h3>1. Upload je postNL label PDF</h3>
          <h3>2. klik op &quot;fix PDF&quot;</h3>
          <h3>3. Download de PDF</h3>
          <h3>4. Print op label printer </h3>
        </div>

        <div className="row mt-5">
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">

            <div className="d-flex justify-content-center">
              <input
                type="file"
                accept="application/pdf"
                className="mt-2 btn btn-dark w-75"
                onChange={e => this.handle(e)}
              />
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-12 mb-5 mt-5 col-sm-12 d-flex justify-content-center align-items-baseline">
            <br />
            {this.state.outputFileName ? (
              <button
                type="button"
                className=" btn btn-dark"
                onClick={e => this.click(e)}
              >
                Fix PDF
              </button>
            ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
              <></>
            )}
          </div>

          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-3">
   
            {this.state.clicked ? (
              <div className="d-flex justify-content-center">
                <a
                  href={this.state.compressedLink}
                  download={this.state.outputFileName}
                  target="_blank"
                  rel="noreferrer"
                  className="mt-2 btn btn-dark w-75"
                >
                  Download
                </a>
              </div>
            ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
              <></>
            )}
          </div>

      <div className="col align-self-center">
        <div className="text-light text-center " >
          <h1>F.A.Q</h1>
          <h4>Mijn label print nogsteeds niet goed? </h4>
          <h4>Probeer je printer in te stellen op &quot;schalen naar papier grootte&quot;</h4>
          <br></br>
          <h4> Is dit een site van PostNL? </h4>
          <h4> Nee, dit is een gratis service die geboren is uit de behoefte om PostNL labels te kunnen printen met een label printer</h4>
          <br></br>
          <h4>Hoe komt het dat ik mijn bestand niet kan downloaden? </h4>
          <h4>Alle bestanden worden 10 minuten nadat ze gemaakt zijn weggegooid om privacy te waarborgen. Je kan de PDF nog een keer uploaden en minder lang wachten met downloaden</h4>
          <br></br>
          <h4>Ik vind deze site zo handig kan ik ergens mee helpen/doneren? </h4>
          <h4> Wat </h4>
          <br></br>
          <h4>hoe kom ik in contact? </h4>
          <h4>stuur een <a href = "mailto:  postnllabelfix@gmail.com">mailtje</a> </h4>
        </div>

        </div>
      </div>
     </div>
    );
  }
}
